import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Exposure to Different Cultures Promotes Acceptance',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/SqmNUZs0bxs',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Exposure to different cultures and ways of life helps people develop an
      awareness of others and of possibilities for the future, furthering their
      acceptance of differences and ability to pursue their full potential.
    </p>
    <p>
      Particularly for young people in the American South, exposure to different
      kinds of people and life opportunities is often cited as an important
      factor in supporting them to realize their full potential.
    </p>
    <p>
      At the community level, physical and social segregation based on race and
      class means many lack exposure to diverse people and groups — an issue
      that reinforces dependence on stereotypes about others and perpetuates
      unequal access to opportunities.
    </p>
    <p>
      Research from Pew suggests that finding common ground may be key to
      improving race relations. More than half of Americans (55 percent) say
      that, when it comes to improving race relations, it is more important to
      focus on what different racial and ethnic groups have in common; 44
      percent say it’s more important to focus on each group’s unique
      experiences. Asian people (58 percent), black people (54 percent), and
      Latino people (49 percent) are more likely than white people (39 percent)
      to say it’s more important to focus on the unique experiences of different
      racial and ethnic groups. Still, 4 in 10 or more of these racial and
      ethnic minorities say the better approach to improving race relations is
      to focus on what different groups have in common. Another recent study
      found that increasing interracial contact is best at reducing racial
      anxiety. And the American Psychological Association noted in a report that
      “one of the best ways to change attitudes is through intergroup contact.”
    </p>
    <p>
      Attitudes are not simply about the way you think about a group; they are
      also about how you feel about a group. In America, white people have been
      able to change their minds about racism faster than they have been able to
      change their deep-seated, and often unconscious, feelings.
    </p>

    <Quote
      quote="There were no doctors in my neighborhood, no lawyers in my neighborhood, you know, everybody just kind of followed the same kind of model."
      person="Black female, 31"
      title="Montgomery"
    />
    <Quote
      quote="Most whites don’t necessarily have to deal with black folk on a daily basis. And so that in essence breeds this sense of racism because we don’t deal with each other, but it’s not always racism, sometimes it’s just, I don’t know you and I don’t deal with you."
      person="Black male, 43"
      title="Knoxville"
    />
    <Quote
      quote="Sometimes the way I talk or if I say certain stuff, or if I put emphasis on certain words she’s like, ‘Wow. You’re being really rude.’ I’m like, ‘Oh, no, I wasn’t being rude. I was just literally talking to you.’ Or like she doesn’t understand my hair and she like wants to touch it all the time, where she doesn’t understand that that’s kind of offensive."
      person="Black female, 19"
      title="Charleston, WV"
    />
    <Quote
      quote="Because kids will only become what...they’ll be what they see. And so if they only see their neighborhood and they only see drug epidemics, and then they go to the school and they are only around people who are dealing with the same thing, and then if they go to a house that their parents don’t necessarily own, there’s a lot of trauma in that of keeping the lights on."
      person="Black male, 29"
      title="Charlotte"
    />
  </Modal>
);
